import {
  CREATE_CONVERSATION_SUCCEEDED,
  MESSAGES_FETCH_SUCCEEDED,
  ONLY_MESSAGES_FETCH_SUCCEEDED,
  ADD_MESSAGE_FOR_NUMBER,
  CHANGE_MESSAGE_FOR_NUMBER,
  CHANGE_NEW_CONVERSATION_ID,
  CHANGE_CONVERSATION_SENDER,
  FETCH_CONTACT_DETAILS_SUCCEEDED,
  MARK_CONVERSATION_READ,
  ADD_INCOMING_MESSAGE_FOR_NUMBER,
  MARK_CONVERSATION_READ_ON_PANEL
} from "../../constants/ActionTypes";
import { getConversationById, getConversationByNumber, isNewConversation } from "../../components/chat/ChatNew/utils";

const initialSettings = [];

const newConversationObject = (recipientNumber, senderNumber) => ({
  participants: [],
  _id: `new-${Date.now()}`,
  account: "first",
  accountNumber: senderNumber,
  clientNumber: recipientNumber,
  messages: [],
  isMessagesFetched: true,
  __v: 0
});

const messages = (state = initialSettings, action) => {
  const conversationList = [...state];
  switch (action.type) {
    case MESSAGES_FETCH_SUCCEEDED:
      return action.forceReload ? action.messages : [
        ...state.filter(existingConversation => action.messages.find(conversation => existingConversation.conversationId === conversation.conversationId)),
        ...action.messages.filter(conversation => !state.find(existingConversation => existingConversation.conversationId === conversation.conversationId))
      ];
    case ONLY_MESSAGES_FETCH_SUCCEEDED: {
        const newConversations = state.map(
            (conversation) =>
                conversation.conversationId === action.conversationId
                    ? { ...conversation, messages: action.messages, isMessagesFetched: true }
                    : conversation
        );
        return [...newConversations];
    }
    case CREATE_CONVERSATION_SUCCEEDED:
      return [
        ...state,
        newConversationObject(action.recipientNumber, action.senderNumber)
      ];
    case ADD_MESSAGE_FOR_NUMBER:
      const conversationToExtend = getConversationByNumber(conversationList, action.message.clientNumber);
      if (conversationToExtend) {
        conversationToExtend.lastMessage = action.message;
        if (conversationToExtend.messages) {
          conversationToExtend.messages.push(action.message);
        }
      }
      return [...conversationList];
    case ADD_INCOMING_MESSAGE_FOR_NUMBER:
      const conversationToExtension = getConversationByNumber(conversationList, action.message.clientNumber);
      if (conversationToExtension) {
        conversationToExtension.lastMessage = action.message;
        conversationToExtension.isRead = false;
        if (conversationToExtension.messages) {
          conversationToExtension.messages.push(action.message);
        }
      }
      return [...conversationList];
    case CHANGE_NEW_CONVERSATION_ID:
      const conversationToChange = getConversationByNumber(conversationList, action.clientNumber);
      if (isNewConversation(conversationToChange._id)) {
          conversationToChange._id = action.id;
      }
      return [...conversationList];
    case CHANGE_MESSAGE_FOR_NUMBER:
      const conversationToChangeMessage = getConversationByNumber(conversationList, action.message.clientNumber);
      if (conversationToChangeMessage && (!conversationToChangeMessage.status || !['UNDELIVERED', 'DELIVERED'].includes(conversationToChangeMessage.status))) {
        conversationToChangeMessage.lastMessage = conversationToChangeMessage.lastMessage.messageId === action.message.messageId ? action.message : conversationToChangeMessage.lastMessage;
        conversationToChangeMessage.messages = conversationToChangeMessage.messages.map(message => message.messageId === action.message.messageId ? {...action.message, last: true} : message);
      }
      return [...conversationList];
    case CHANGE_CONVERSATION_SENDER:
      const conversationToChangeSender = getConversationByNumber(conversationList, action.clientNumber);
      conversationToChangeSender.accountNumber = action.senderNumber;
      return [...conversationList];
    case FETCH_CONTACT_DETAILS_SUCCEEDED:
      const conversationToSetContact = getConversationByNumber(conversationList, action.contact.phone);
        conversationToSetContact.contactDetails = action.contact;
      return [...conversationList];
    case MARK_CONVERSATION_READ:
    case MARK_CONVERSATION_READ_ON_PANEL:
      const conversationToMarkRead = getConversationById(conversationList, action.conversationId);

      if (!conversationToMarkRead.isRead) {
        conversationToMarkRead.isRead = true;
      }

      return [...conversationList];
    default:
      return state;
  }
};

export default messages;
