import antdPl from "antd/lib/locale/pl_PL";
import appLocaleData from "react-intl/locale-data/pl";
import plMessages from "../locales/pl.json";
import 'moment/locale/pl';

const PlLang = {
  messages: {
    ...plMessages
  },
  antd: antdPl,
  locale: 'pl',
  data: appLocaleData
};
export default PlLang;
